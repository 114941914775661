<template>
    <section class="page-1" ref="pageOne">
        <div class="carousel">
            <div
                class="item"
                :class="{ main: index === currentIndex }"
                v-for="(info, index) in donationInfo"
                :key="index"
                :style="{ '--index': currentIndex }"
            >
                <!-- {{this_a = index}} -->
                {{ info.name }}
                <p style ="font-size:17px;color:#fff;margin-top:100px;">籌款金額</p>
                <p style ="font-size:3vw;color:#fff;margin-top:60px;">{{info.total_fundraise_amount}}</p>
                <p style ="font-size:19px;color:#fff;margin-top:25px;">捐款人數：{{info.total_number_donations}}人</p>
            </div>
        </div>
        <!-- <div class="title-group">
            <span>預計籌款金額</span>
            <span>0</span>
            <span>捐款人數：1人</span>
        </div> -->
        <router-link style="text-decoration: none;" to="/donate/0/1" v-slot="{ navigate }">
            <primary-button class="primary-button" style="margin-top:13vh;">
                立即捐款
            </primary-button>
        </router-link>
         <primary-button class="primary-button" @click="navigate">
                案件求助
            </primary-button>
    </section>
    <section class="page-2" ref="pageTwo">
        <h1>爲何選擇我們</h1>
        <h2>Why us</h2>
        <div class="card-group">
            <div class="card" v-for="(reason, index) in reasons" :key="index">
                <img :src="reason.icon" />
                <div class="description">
                    <h3>{{ reason.title }}</h3>
                    <p>{{ reason.detail }}</p>
                </div>
            </div>
        </div>
    </section>
    <section class="page-3" ref="pageThree">
        <h1>{{network}}</h1>
        <h2>About us</h2>
        <div class="content">
            <img src="../assets/img/iMeddy-logo-lg.png" alt="iMeddy's Logo" />
            <div class="description">
                <h3 @click="f1">iMeddy籌款平台</h3>
                <p>
                    iMeddy籌款平台是香港專注於健康慈善項目的籌款平台，透過移動互聯網技術將香港「互助互濟」的線下善舉數碼化，通過親友分享、移動支付等方式讓受款者更有效率獲得幫助。
                </p>
            </div>
        </div>
    </section>
    <section class="page-4" ref="pageFour">
        <h1>媒體報導</h1>
        <h2>Media Report</h2>
        <div class="report-container">
            <router-link
                v-for="article in displayedArticles"
                :key="article.id"
                :to="{ name: 'news', params: { id: article.id } }"
                v-slot="{ navigate }"
                custom
            >
                <div class="report" @click="navigate">
                    <img :src="article.icon" :alt="article.title" />
                    <div class="content">
                        <span class="title">{{ article.title }}</span>
                        <span class="date">{{ article.date }}</span>
                    </div>
                </div>
            </router-link>
        </div>
    </section>
</template>

<script>
import api from "@/api";
import { apiLogin } from '@/request/api';
import { apiDoctorWeekly } from '@/request/api';
import { mapState } from 'vuex';
let that;
function onScroll() {
    let pageOneHeight = that.$refs.pageOne.clientHeight,
        pageTwoHeight = that.$refs.pageTwo.clientHeight,
        pageThreeHeight = that.$refs.pageThree.clientHeight,
        pageFourHeight = that.$refs.pageFour.clientHeight,
        scrollTop = document.documentElement.scrollTop;
    let pageHeightList = [
            pageOneHeight,
            pageTwoHeight,
            pageThreeHeight,
            pageFourHeight,
        ],
        currentPage = 0;
    while (scrollTop >= -80) {
        scrollTop -= pageHeightList[currentPage];   
        currentPage++;
    }
    switch (currentPage) {
        case 1: 
        case 3:
            that.setNavBarThemeToTransparent();
            break;
        case 2:
        case 4:
            that.setNavBarThemeToWhite();
            break;
    }
}
export default {
    data() {
        return {
            currentIndex: 0,
            direction: 1,
            timerId: null,
            this_a:123,
            reasons: [
                {
                    icon: require("../assets/icon/index-icon-1.svg"),
                    title: "0收費",
                    detail: "本平台不會瓜分任何籌得的款項，以幫助患者「籌得更多、取得更快」為目標，確保每一分每一毫善款都捐助給受眾。",
                },
                {
                    icon: require("../assets/icon/index-icon-2.svg"),
                    title: "1對1顧問服務",
                    detail: "每單案件都會有專人跟進，從申請、發起、傳播、審核、提現各環節給予用戶一對一指導。",
                },
                {
                    icon: require("../assets/icon/index-icon-3.svg"),
                    title: "公開透明",
                    detail: "每一筆捐款及每一筆支出的詳細資料也會實時展示在平台上，公眾可實時監測每一個籌款項目的資金流。",
                },
                {
                    icon: require("../assets/icon/index-icon-4.svg"),
                    title: "邊籌邊取",
                    detail: "籌款期内可根據治療需求，隨時申請提現",
                },
                {
                    icon: require("../assets/icon/index-icon-5.svg"),
                    title: "嚴格審查",
                    detail: "每一個籌款項目也會作嚴格核實，包括實名認證、病歷認證、收款人認證和朋友認證，發起項目必需通過全部認證才可以開始籌款。特別加入檢舉功能，確保籌款項目百分百真實。",
                },
                {
                    icon: require("../assets/icon/index-icon-6.svg"),
                    title: "資金托管",
                    detail: "iMeddy籌款平台為保障平台所有籌款資金的安全，已與第三方慈善機構達成合作，籌款資金將直接進入慈善機構主體的獨立戶口，與平台自有資金隔離，實行獨立資金托管。",
                },
                {
                    icon: require("../assets/icon/index-icon-7.svg"),
                    title: "反詐行動",
                    detail: "對於任何涉嫌違法犯罪的虛假隱瞞行為，iMeddy籌款平台保持零容忍，一經發現，會第一時間啟動核查，上報並協助警方等有關部門，采取法律手段嚴厲打擊，堅決捍衛愛心人士的善心善舉。",
                },
            ],
            reports: [
                {
                    poster: require("../assets/img/index-page-3-background.jpg"),
                    title: "標題標題標題",
                    date: "2021年7月21日",
                    id: "1",
                },
                {
                    poster: require("../assets/img/index-page-3-background.jpg"),
                    title: "標題標題標題",
                    date: "2021年7月21日",
                    id: "2",
                },
                {
                    poster: require("../assets/img/index-page-3-background.jpg"),
                    title: "標題標題標題",
                    date: "2021年7月21日",
                    id: "3",
                },
            ],
            donationInfo: [
                {
                    name:'',
                    amount: 0,
                    noOfDonations: 0,
                    total_fundraise_amount:0,
                    total_number_donations:0
                }
                // {
                //     name: "醫療項目",
                //     amount: 1000000,
                //     noOfDonations: 59090,
                // },
                // {
                //     name: "分類1",
                //     amount: 1200000,
                //     noOfDonations: 9090,
                // },
                // {
                //     name: "分類2",
                //     amount: 1000,
                //     noOfDonations: 5909,
                // },
                // {
                //     name: "分類3",
                //     amount: 1000700,
                //     noOfDonations: 59,
                // },
                // {
                //     name: "分類4",
                //     amount: 1000656,
                //     noOfDonations: 86,
                // },
            ],
            articles: [],
        };
    },
    created() {
        var _this=this
        var data = {
            account:'yy',
            password:'MTIzNDU2Nzg5',
            type:'1',
        };

        api.category.getCategorys().then(response => {
            let data = response.data.results;
            let donationInfo = [];
            for(let i=0; i<data.length; i++){
                donationInfo.push({
                    name:data[i].name,
                    amount: 0,
                    noOfDonations: 0,
                    total_fundraise_amount: data[i].totalFundraiseAmount,
                    total_number_donations: data[i].totalNumberDonations
                });
            }
            this.donationInfo = donationInfo;
        }).catch(error => {
            console.log(error);
        });
    },
    computed: {
        thisA(){
            return this.this_a;
        },
        noOfDonations() {
            return this.donationInfo[
                this.currentIndex
            ].noOfDonations.toLocaleString();
        },
        displayedArticles() {
            // TODO: 排序规则还不确定，这里先瞎写的
            this.articles.sort((a, b) => {
                return a.sort - b.sort;
            });
            // 只显示头三篇
            return this.articles.slice(0, 3).map((e) => {
                return {
                    id: e.id,
                    title: e.title,
                    icon: e.icon,
                    date: e.createDatetime.split(" ")[0],
                };
            });
        },
    },
    watch: {
        currentIndex() {
            if (this.currentIndex > this.donationInfo.length - 1)
                this.currentIndex = 0
            // if (
            //     this.currentIndex >= this.donationInfo.length - 1 ||
            //     this.currentIndex <= 0
            // )
            //     this.direction = -1 * this.direction;
        },
        thisA(){
            // console.log(77);
        }
    },
    mounteds:{
    },
    methods:{
        navigate(){
            this.$store.commit("updateStore");
            this.$router.push('new-donation');
        },
        fileUrl(file) {
            return process.env.VUE_APP_SERVER +file;
        },
    },
    mounted() {
        that = this;
        this.timerId = setInterval(() => {
            that.currentIndex += that.direction;
        }, 1000);
        window.addEventListener("scroll", onScroll);
        onScroll();
        api.article
            .getArticles()
            .then((res) => {
                res.data.results.forEach((e) => {
                    this.articles.push({
                        id: e.id,
                        sort: e.sort,
                        status: e.status,
                        title: e.title,
                        icon: this.fileUrl(e.icon),
                        content: e.content,
                        createDatetime: e.createDatetime,
                    });
                });
            })
            .catch((error) => {
                console.log(error);
            });
    },
    unmounted() {
        clearInterval(this.timerId);
        window.removeEventListener("scroll", onScroll);
    },
};
</script>

<style lang="scss" scoped="true">
// 全局样式
* {
    // 导航栏预留位置
    --nav-height: 80px;
    --gap: 32px;
}
section {
    h1 {
        margin-top: 20px;
        font-size: 40px;
        color: var(--page-font-color);
    }
    h2 {
        font-size: 24px;
        position: relative;
        margin: 16px 0;
        color: var(--page-font-color);
        &::before,
        &::after {
            content: "";
            width: 130%;
            height: 2px;
            background-color: var(--page-font-color);
            position: absolute;
            top: calc(50% - 1px);
        }
        &::after {
            right: -140%;
        }
        &::before {
            left: -140%;
        }
    }
}
// 第一页相关样式
.page-1 {
    background-image: url("../assets/img/index-page-1-background.png");
    background-position: center;
    background-size: cover;
    overflow: hidden;
    min-height: 100vh;
}
.carousel {
    --no-of-items: 5;
    --carousel-width: 80vw;
    --item-margin: 20px;
    --item-width: calc(
        (
                var(--carousel-width) - (var(--no-of-items) - 1) * 2 *
                    var(--item-margin)
            ) / (var(--no-of-items) - 1 + var(--main-to-normal-ratio))
    );
    --main-to-normal-ratio: 2;
    font-size: 32px;
    background: linear-gradient(
        to left,
        rgba(255, 255, 255, 0),
        $white 15%,
        $white 85%,
        rgba(255, 255, 255, 0)
    );
    background-clip: text;
    width: var(--carousel-width);
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    margin: 230px auto 0;
    height: 430px;
    .item {
        color: transparent;
        text-align: center;
        flex-shrink: 0;
        display: inline-block;
        margin: 0 var(--item-margin);
        font-size: 1em;
        width: var(--item-width);
        transition: all 0.3s ease;
        // line-height: 150px;
        height: 150px;
        &:first-child {
            margin-left: calc(
                ((var(--no-of-items) - 1) / 2 - var(--index)) *
                    (var(--item-margin) * 2 + var(--item-width))
            );
        }
        &.main {
            font-size: 2em;
            width: calc(var(--main-to-normal-ratio) * var(--item-width));
        }
    }
}
.title-group {
    width: 80vw;
    margin: 0 auto;
    text-align: center;
    span {
        display: block;
        margin: 32px 0;
        color: $white;
    }
    span:nth-of-type(1),
    span:nth-of-type(3) {
        font-size: 20px;
    }
    span:nth-of-type(2) {
        font-size: unquote("min(15vw, 128px)");
    }
}
.primary-button {
    width: 60vw;
    max-width: 400px;
    margin: 32px auto;
    font-size: 18px;
}
// 第二页相关样式
.page-2 {
    --page-font-color: #{$green-500};
    min-height: 100vh;
    text-align: center;
    padding: var(--nav-height) var(--gap) var(--gap);
    @include fc-c-c;
}
.card-group {
    max-width: 1200px;
    flex-grow: 1;
    @include fr-b-st;
    flex-wrap: wrap;
    margin: 32px 0;
    .card {
        flex: 1 0 auto;
        min-width: 260px;
        width: 20%;
        margin: 10px 8px;
        border-radius: 16px;
        background: $green-100;
        box-shadow: 0 0 6px -1px rgba($color: $gray-900, $alpha: 0.3);
        display: grid;
        padding: 16px;
        grid-template-rows: 2fr 3fr;
        align-items: center;
        justify-items: center;
        max-height: 320px;
        color: $gray-900;
        .description {
            align-self: flex-start;
            @include fc-s-c;
            h3 {
                font-size: 28px;
                margin-bottom: 16px;
            }
            p {
                line-height: 1.4;
                text-align: justify;
            }
        }
    }
}
// 第三页相关样式
.page-3 {
    @include fc-c-c;
    --page-font-color: #{$white};
    color: var(--page-font-color);
    background-color: black;
    padding: var(--nav-height) var(--gap) var(--gap);
    min-height: 100vh;
    background-image: url("../assets/img/index-page-3-background.jpg");
    background-position: center;
    background-size: cover;
    .content {
        max-width: 1060px;
        flex-grow: 1;
        @include fr-c-c;
        flex-wrap: wrap;
        padding: 0 16px;
        img,
        .description {
            flex-grow: 1;
            width: 30%;
            min-width: 250px;
            margin: 0 16px;
            max-height: 300px;
        }
        img {
            object-fit: contain;
        }
        .description {
            @include fc-c-c;
            h3 {
                font-size: 28px;
                margin-bottom: 16px;
                color: $white;
            }
            p {
                text-align: justify;
                line-height: 1.4;
                color: $white;
            }
        }
    }
}
// 第四页相关样式
.page-4 {
    --page-font-color: #{$green-500};
    padding: var(--gap);
    @include fc-c-c;
    .report-container {
        @include fr-a-c;
        flex-wrap: wrap;
        .report {
            cursor: pointer;
            width: 30%;
            flex-grow: 1;
            margin: calc(var(--gap) / 2);
            min-width: 250px;
            img {
                width: 100%;
            }
            .content {
                @include fc-s-s;
                .title {
                    font-weight: 600;
                    font-size: 20px;
                    margin: 8px 0;
                }
                .date {
                    font-size: 14px;
                    line-height: 1.2;
                }
            }
        }
    }
}
// 自适应调整
@media screen and (max-width: 376px) {
    * {
        --gap: 16px;
    }
    .primary-button {
        font-size: 16px;
        height: 30px;
        line-height: 30px;
    }
    .carousel {
        --no-of-items: 3;
        --carousel-width: 100vw;
        --main-to-normal-ratio: 2;
        --item-margin: 1px;
        font-size: 18px;
        margin: 15vh auto 0;
        height: 100px;
        .item {
            height: 100px;
        }
    }
}
</style>